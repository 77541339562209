/** @type {import('tailwindcss').Config} */ 
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-responsive-carousel/lib/styles/carousel.min.css';

@import url('./shadows.css');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;400;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* TODO: reescribir de manera anidada y legible */

body {
  @apply text-purple-900;
}

.layout-main-section-container {
  /* background: url('/public/fondo\ -\ original.jpg'); */
  background-repeat: repeat;
  background-size: 300%;
}

@screen sm {
  .layout-main-section-container {
    background-size: 200%;
  } 
}

@screen md {
  .layout-main-section-container {
    background-size: 150%;
  } 
}

@screen lg {
  .layout-main-section-container {
    background-size: 100%;
  } 
}

.layout-main-section {
  @apply bg-white/70;
}

.container-width {
  @apply sm:max-w-xl md:max-w-4xl lg:max-w-4xl mx-auto;
}

.text-priority-higth {
  @apply text-xs xs:text-sm sm:text-base md:text-lg;
}

.text-priority-normal {
  @apply text-[10px] xs:text-xs sm:text-sm md:text-base;
}

.product-explorer {
  @apply container-width;
}


/*
Filters
*/
.filters {
  @apply max-w-lg mx-auto;
}
.filters-container .filter {
  @apply bg-white text-stone-700 border-stone-300 border px-3 py-1.5 capitalize font-medium w-auto
  hover:bg-stone-200/70 shadow-lg text-priority-higth;
}
.filters-container .filter:first-child {
  @apply rounded-l;
}
.filters-container .filter:last-child {
  @apply rounded-r;
}
.filters-container .filter.selected {
  @apply bg-stone-400 text-white hover:bg-stone-400 hover:text-stone-200
  text-priority-higth;
}

/* 
Btn List
*/
.btn-list {
  @apply container-width;
}
.btn-list .btn-capsule {
  @apply inline bg-white border-stone-300 border text-stone-700 capitalize
    mb-1.5 mx-1 rounded-2xl shadow hover:border-stone-500 hover:shadow-lg cursor-pointer px-2.5 py-1
    text-priority-normal;
}
.btn-list .btn-capsule.selected {
  @apply scale-110 bg-stone-400 text-white;
}

/* 
Label List
*/
.label-list {
  @apply flex place-content-center;
}
.label-list .item {
  @apply border-stone-300 text-stone-500 bg-transparent
    rounded-2xl border uppercase text-[12px] font-normal
    mx-1 mb-1.5 px-2 py-0.5 cursor-pointer tracking-wide;
}

/*
Products Sectors
*/
.products-sectors {
  @apply container-width text-stone-700;
}
.products-sectors h2 {
  @apply text-xl md:text-2xl font-thin uppercase tracking-tight;
}
.products-sectors .sector + .sector {
  @apply mt-8;
}

/*
Product Grid
*/
.products-grid {
  @apply grid grid-cols-3 md:grid-cols-4 gap-1 xs:gap-2 sm:gap-4 lg:gap-6;
}
/* card */
.products-grid .product-card {
  @apply flex flex-col bg-white border border-stone-300 rounded shadow hover:shadow-lg  mb-1.5 md:mb-3 cursor-pointer overflow-hidden;
}
.products-grid .product-card img,
.products-grid .product-card .img-holder {
  @apply h-20 xs:h-28 xsm:h-36 w-full object-cover;
}
.products-grid .product-card .img-holder {
  @apply bg-stone-200;
}
.products-grid .product-card .meta {
  @apply px-0.5 py-1 xsm:px-1 sm:py-1.5 w-full;
}
.products-grid .product-card h3 {
  @apply text-stone-700 text-xs sm:text-base font-semibold leading-4;
}
.products-grid .product-card .price {
  @apply text-green-700 mt-1 font-mono text-xs sm:text-sm;
}
.products-grid .product-card.selected {
  @apply border-2 border-amber-200 scale-105 shadow-md;
}
/* grid sizes */
/* xs */
.products-grid.xs {
  @apply grid grid-cols-4 md:grid-cols-5 gap-2 md:gap-3 lg:gap-4;
}
.products-grid.xs .product-card {
  @apply rounded-xl shadow-none;
}
.products-grid.xs .product-card img,
.products-grid.xs .product-card .img-holder {
  @apply h-14 xs:h-16 xsm:h-24 scale-105;
}
/* xxs */
.products-grid.xxs {
  @apply grid grid-cols-4 gap-2 md:gap-1;
}
.products-grid.xxs .product-card {
  @apply rounded-xl shadow-none md:mb-1;
}
.products-grid.xxs .product-card img,
.products-grid.xxs .product-card .img-holder {
  @apply h-14 xs:h-16 xsm:h-20 md:h-16 scale-105;
}
/* product in cart */
.product-card.in-cart { 
  @apply border-[3px] border-lime-500;
}
.product-card.in-cart.xs { 
  @apply border-[2px] border-lime-500;
}


/*
Product Public View
*/
.product-public-view {}
.product-public-view.with-border {
  @apply p-2 xs:p-2.5 sm:p-3 md:p-8 mt-4 rounded-md shadow-xl bg-white border-2 border-amber-200;
}

.shaked {
  animation: shake 0.5s ease;
  animation-iteration-count: 3;
  animation-delay: 2.5s;
}
.shaked-2s {
  @apply shaked;
  animation-delay: 2.4s;
}

.shaked-cascade-1 {
  @apply shaked;
  animation-iteration-count: 1;
  animation-delay: 1s;
}

.shaked-cascade-2 {
  @apply shaked;
  animation-iteration-count: 1;
  animation-delay: 1.6s;
}

.shaked-cascade-3 {
  @apply shaked;
  animation-iteration-count: 1;
  animation-delay: 2.4s;
}


@keyframes shake {
	0%, 100% {transform: translateX(0);}
	20%, 60%, 100% {transform: translateX(-3px);}
	40%, 80% {transform: translateX(3px);}
}

.spin-slow {
  animation:spin 60s linear infinite;
}

@keyframes spin {
	100% { transform: rotate(360deg); }
}

.legend {
  background: none !important;
  opacity: 1;
  transition: none !important;
}